.supporters {
  max-width: var(--c10);
  --grid-cell: 12rem;
  --grid-gap: 3rem;
}

.supporters__supporter {
  display: grid;
  place-items: center;
}
