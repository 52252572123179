.argument {
  --_argument-spacing: 6em;
  --_index-bg: var(--argument-index-bg, var(--fg));
  --_index-color: var(--argument-index-color, var(--bg));

  margin-top: var(--_argument-spacing);
  margin-bottom: var(--_argument-spacing);

  display: flex;
  flex-flow: wrap;
  gap: var(--grid-gap);

  @media (--media-md) {
    flex-flow: nowrap;

    &:nth-child(2n + 1) {
      .argument__figure {
        order: -1;
      }
    }
  }

  &:first-child {
    margin-top: calc(var(--spacing) + var(--_argument-spacing));
  }
}

.argument__content {
  position: relative;
  flex: 1 1 var(--c7);
}

.argument__text {
  padding: var(--spacing);

  & *:first-child {
    margin-top: 1em;
  }
  & *:last-child {
    margin-bottom: 1em;
  }
}

.argument__figure {
  flex: 1 1 var(--c5);
}

.argument__index {
  position: absolute;
  font-size: 2.6em;

  top: calc((1 / 2.6 - 1) * var(--spacing));
  left: calc(var(--spacing) / 2.6);

  background-color: var(--_index-bg);
  color: var(--_index-color);
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  aspect-ratio: 1/1;
}
