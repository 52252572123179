.button {
  --_bg: var(--button-bg, var(--fg));
  --_fg: var(--button-color, var(--bg));

  appearance: none;
  border: none;

  display: inline-flex;
  align-items: center;
  gap: var(--grid-gap);
  /* border-radius: 0.5em; */
  padding: 0.75rem 1.5rem;
  cursor: pointer;

  text-decoration: none;
  font-weight: bold;

  margin-top: auto;

  color: var(--_fg);
  background: var(--_bg);

  --icon-size: 1.2em;
  .icon {
    transform: translateX(0.25em);
  }

  &:hover,
  &:focus-visible {
    color: var(--_bg);
    background: var(--_fg);
  }
}

.button--accent {
  --_bg: var(--accent);
  --_fg: var(--accent-fg, var(--fg));

  &:hover,
  &:focus-visible {
    color: var(--_fg);
    background: var(--_bg);
    filter: saturate(0.9) brightness(0.75) contrast(165%);
  }
}

.button--clean {
  background: none;
  border: none;
  color: var(--_bg);
  .icon {
    fill: var(--_bg);
  }
  &:hover,
  &:focus-visible {
    background: none;
    border: none;
    color: var(--_bg);
    .icon {
      fill: var(--_bg);
    }
  }
}

.button--arrow-back {
  .icon {
    transform: translateX(-0.25em);
  }
}

.button--right {
  align-self: flex-end;
}
