@supports (grid-template-rows: masonry) {
  .masonry {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: masonry;
  }

  .masonry__cell {
    /* Reset the effect of the cell size classes */
    grid-column-end: span 1 !important;
  }
}

@supports not (grid-template-rows: masonry) {
  .js .masonry {
    display: grid;
    column-gap: var(--grid-gap);

    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    grid-auto-rows: 8px;

    --columns: 1;
    @media (--media-md) {
      --columns: 2;
    }
    @media (--media-lg) {
      --columns: 3;
    }
  }

  .masonry__cell {
    grid-row-end: span 10; /* Minimal initial height */
  }
}
