.testimonials__create-button-container {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

.testimonials__search {
  input.input__search {
    background-color: inherit;
    border-bottom: 1px solid;
    margin-right: 1em;
    max-width: 100%;
  }
}

.testimonials-grid {
  row-gap: 0; /* Negative margin on __box is a sufficient gap */
}

.testimonial {
  --overlap-spacing: 3.25rem;
  width: 100%;
  position: relative;
}

.testimonial__box {
  padding: var(--overlap-spacing) var(--spacing) var(--spacing);
}

.testimonial__image {
  margin-left: var(--spacing);
  transform: translateY(var(--overlap-spacing));
  max-width: 33.333%;
}

.testimonial__name {
  margin-top: 1em;
  font-weight: bold;
  font-size: 1.125rem;
}

.testimonial__profession {
  font-size: 1.125rem;
  margin-bottom: 0.25em;
}

.testimonial__statement {
  margin: 1em 0;
  font-weight: bold;
  hyphens: auto;
  overflow-wrap: break-word;
}

.testimonial__sharing {
  --icon-size: calc(1.5 * var(--interact-size));
  margin-top: var(--grid-gap);
  display: flex;
  gap: 0.5em;
}

.testimonial__separator {
  border-bottom: 1px solid currentColor;
}

.testimonial__show-more {
  margin: var(--margin) 0;
  display: flex;
  justify-content: center;
  gap: var(--grid-gap);
}

.image-cropping__wrap {
  margin-bottom: var(--grid-gap);

  .imagefield {
    margin-bottom: var(--grid-gap);
  }

  .imagefield-preview {
    display: none;
  }
}

.image-cropping {
  max-width: 100%;
}

#testimonial-preview {
  margin-top: var(--grid-gap);
}

.testimonials__image-preview {
  margin-bottom: var(--grid-gap);
}

#testimonial-preview {
  background-size: contain;
}

/*featured testimonials*/
.featured-testimonials-lead {
  h1 {
    margin-bottom: 0;
  }
}

@media (--media-md) {
  .testimonial--featured {
    display: flex;

    .testimonial__image {
      margin-left: 0;
      position: relative;
      z-index: 1;
      padding-bottom: calc(1.5 * var(--overlap-spacing));
      padding-top: var(--overlap-spacing);
    }

    .testimonial__box {
      flex-grow: 1;
      margin: 0;
      margin-left: calc(-1 * var(--overlap-spacing));
      padding-left: calc(var(--overlap-spacing) + var(--spacing));
    }

    .testimonial__statement {
      font-size: 2rem;
    }

    .testimonial__name,
    .testimonial__profession {
      text-align: right;
    }
  }
}
