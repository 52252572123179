:root {
  --white: #ffffff;
  --black: #08080d;

  --fg: #08080d;
  --bg: #ffffff;

  --accent: #d49aef;
  --accent-fg: var(--black);

  --link-color: inherit;

  --errors-fg: hsl(0, 100%, 27%);

  --menu-bg: var(--black);
  --menu-fg: var(--white);
  --menu-button-bg: var(--accent);
  --menu-button-color: var(--black);
}
