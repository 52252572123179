.plugin--engagement-boxes {
  margin: 0 auto;
}

.engagement-boxes {
  --grid-cell: var(--c4);
}

.engagement-box {
  padding: var(--spacing);
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  /* I'm too dumb to find the reason for the horizontal scrollbars. Force them away. */
  max-width: calc(100vw - 2 * var(--grid-gap));
  overflow-x: hidden;

  background: var(--accent, var(--bg));
  color: var(--fg);

  .form {
    font-size: inherit;
  }
}

.engagement-box__button {
  /* text-align: center; */
  margin-top: auto;
}

.engagement-box__some-links {
  font-size: var(--interact-size);
  margin-top: auto;
  display: flex;
  gap: 1rem;

  .some-link {
    display: flex;
  }
}
