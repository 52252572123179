@font-face {
  font-family: EncodeSans;
  font-weight: 400;
  font-style: normal;
  src:
    local("EncodeSans-Medium"),
    url(../fonts/Encode/EncodeSans-Medium.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: EncodeSans;
  font-weight: 700;
  font-style: normal;
  src:
    local("EncodeSans-Black"),
    url(../fonts/Encode/EncodeSans-Black.woff2) format("woff2");
  font-display: swap;
}
