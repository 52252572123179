.swiper {
  --swiper-pagination-bottom: -5px;
  --swiper-navigation-color: #303050;
  --swiper-pagination-color: #303050;
  --swiper-pagination-bullet-inactive-color: #404060;
  --swiper-pagination-bullet-size: 12px;
  --swiper-navigation-top-offset: calc(50% - 1em);

  margin-top: var(--margin);
  margin-bottom: var(--margin);

  h1 + & {
    margin-top: 0;
  }

  @media (--media-sm-only) {
    --swiper-navigation-size: 30px;
    --swiper-pagination-bullet-size: 9px;
  }

  .swiper-wrapper {
    max-height: calc(100vh - 8em);
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    display: grid;
    place-items: center;
    height: 100%;
  }

  .swiper-slide img {
    max-height: calc(100vh - 8em);
    display: block;
    object-fit: contain;
  }
}
