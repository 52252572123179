:root {
  --red: #eb5e57;
  --blue: #003a62;
  --green: #219d84;

  --footer-color: var(--white);
  --footer-bg: var(--blue);

  --accent: var(--red);
  --accent-fg: var(--white);

  --menu-bg: var(--blue);
  --menu-button-color: var(--white);

  --faq-bg: color-mix(in srgb, var(--blue), transparent 85%);
}

body {
  font-family: EncodeSans, Helvetica, Arial, sans-serif;
}

.header .logo-container {
  height: 4.5rem;
}

.header,
.hero-container,
.footer {
  --accent: var(--blue);
}

.nav-toggle {
  --icon-color: var(--white);
}

.hero-container .portrait img,
.hero-container .landscape img {
  object-fit: contain;
  max-height: calc(100vh - 4rem);
  max-width: 85.51rem;
  margin: 4rem auto 0;
}

.accented {
  --fg: var(--white);
  --bg: var(--black);
}

.accented .form--wide-fields select {
  --bg: var(--white);
}

.engagement-box:nth-child(3n + 1) {
  --fg: var(--white);
  --button-color: var(--black);
}
.engagement-box:nth-child(3n + 2) {
  --accent: var(--green);
  --fg: var(--white);
  --button-color: var(--black);
}
.engagement-box:nth-child(3n + 3) {
  --accent: var(--blue);
  --fg: var(--white);
  --button-color: var(--black);
}

.plugin--button {
  text-align: left;
}
